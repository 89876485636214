.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.carousel-content {
  display: flex;
  transition: transform 500ms ease-out;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 12%;
  flex-shrink: 0;
  flex-grow: 1;
}
.carousel-content.show-20-icon > * {
  width: 3%;
}
.carousel-content.show-1-icon > * {
  width: 70%;
}
.carousel-content.show-2-icon > * {
  width: 30%;
}

.carousel-content.show-3-icon > * {
  width: calc(100% / 3);
}

.carousel-content.show-4-icon > * {
  width: calc(100% / 4 - 5px);
}
.carousel-content.show-5-icon > * {
  width: calc(100% / 6);
}
.carousel-content.show-13-icon > * {
  width: calc(100% / 13);
}
.carousel-content.show-1-photo > * {
  width: 80%;
}
.carousel-content.show-2-photo > * {
  width: 55%;
}

/* .carousel-content.show-3-photo > * {
    width: calc(100% / 3);
} */

.carousel-content.show-4-photo > * {
  width: calc(100% / 4);
}
.carousel-content.show-1-speechBox > * {
  width: 140%;
}
.left-arrow-icon,
.right-arrow-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 24px;
  backdrop-filter: blur(15px);
  /* background-color: white; */
  border: 1px solid black;
}
.left-arrow-photo,
.right-arrow-photo {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  /* background-color: white; */
  border: 1px solid black;
}
.left-arrow-speechBox,
.right-arrow-speechBox {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  /* background-color: white; */
  border: 1px solid black;
}

.left-arrow-photo {
  left: 1px;
}

.right-arrow-photo {
  right: 1px;
}
.left-arrow-speechBox {
  left: 1px;
}

.right-arrow-speechBox {
  right: 1px;
}
.left-arrow-icon {
  height: 35px;
  width: 35px;
  left: 1px;
  margin-top: -15px;
}
.right-arrow-icon {
  height: 35px;
  width: 35px;
  right: 1px;
  margin-top: -15px;
}
.div-blur-icon {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(15px);
  width: 60px;
  background-color: rgba(255, 255, 255, 0.007);
}
.div-blur-photo {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(15px);
  width: 60px;
  background-color: rgba(255, 255, 255, 0.007);
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow-icon,
  .left-arrow-photo,
  .right-arrow-icon,
  .right-arrow-photo {
    display: none;
  }
}
