.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}
.scroll-to-top-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    transition: opacity 0.2s;
}
.scroll-to-top-button:hover {
    opacity: 0.8;   
}
