@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200&display=swap");

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:-webkit-autofill,
.MuiInputBase-root:has(> input:-webkit-autofill) {
  background-color: #fff;
}

Define the style for the scrollbar ::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

/* Define the style for the thumb */
::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
  border-radius: 0;
}

/* Define the style for the track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0;
}
