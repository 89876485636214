.indicator-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 20px 0;
}

.indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 5px;
  background: white;
  border: 1px solid black;
}

.indicator.active {
  background: black;
}
